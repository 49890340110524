import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch,faEye,faEllipsisH,faEdit,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Table,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import moment from "moment-timezone";
import PlaceholderList from '../components/Placeholder';
import API from '../../config/Api';

import { Routes } from "../../routes";
import subjects from '../../data/subjects'
import { withSwal } from 'react-sweetalert2';
import SweetAlert2 from 'react-sweetalert2';
import { toast } from 'react-toastify';
class JobList extends React.Component{

    constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading:true,
      page : 1,
      DisableLoadmore:false,
    }

  }
    
  componentDidMount() {
    console.log("componentDidMount")
    document.title = `Jobs list | ${process.env.REACT_APP_NAME}` 
    
    this._fetchProjects();
  }


  _fetchProjects = ()=> {
    API.get(`/project-list`,{
      params: {
        page: this.state.page,
        status: [1]
      }
   }).then((response) => {
    let PageNumber = this.state.page
     console.log(response.data.data)
     this.setState({
     list:response.data.data,
     page: PageNumber +1,
     loading:false
     })
     if(response.data.last_page){
       this.setState({
         DisableLoadmore:true
       })
     }
   });
  }

  _handleDeleteProjects = (event,projectId) => {

    console.log('event',projectId)
    if(window.confirm('Do you want Delete this prodject')){

      API.post(`/projectdelete`,{project_id:projectId}).then((response) => {

        console.log('Response',response)
        this._fetchProjects();
        toast.success("Project Deleted Successfully")

     });

    }

   



  }

  HandleLoadMore = (e)=> {

    API.get(`/project-list`,{
      params: {
        page: this.state.page,
        status: [1]
      }
    }).then((response) => {
     if(response.data.data.length == 0 || response.data.last_page){
       this.setState({
         DisableLoadmore:true
       })
     }
     let PageNumber = this.state.page
      this.setState({
      list:[...this.state.list,...response.data.data],
      page: PageNumber +1,
      loading:false
      })
    });
  }

  render(){

    const totaljobs = this.state.list.length;

    const TableRow = (props) => {
    const { id,project_title,project_time,createdAt,zipcode,consumer_details } = props;

    return (
      <tr>
        <td><span className="fw-normal">{id}</span></td>
        <td style={{
          width: "200px",
          wordWrap: "break-word"
        }}><span className="fw-normal" >{project_title}</span></td>
    
        <td><span className="fw-normal">{moment(project_time).format('Do MMM YY')}</span></td>
        <td><span className="fw-normal">{consumer_details.first_name} {consumer_details.last_name} </span></td>
        <td><span className="fw-normal">{zipcode}</span></td>
        <td><span className="fw-normal">{moment(createdAt).format('Do MMM YY')}</span></td>

      
        {/* <td><span className="fw-normal">
          {
            (active == 0) ? <Button onClick={this.onClickActive.bind(this, id)} variant='success'> Activate </Button>  : <Button onClick={this.onClickActive.bind(this, id)}  variant='danger' > Deactivate </Button>  
          }
       
          </span></td> */}
        <td><Card.Link as={Link} to={`/job/${id}`} className="fw-normal">
                 <Button size='sm' type="button" className="mx-2"  variant='outline-primary' > View </Button> 
             </Card.Link>
           
           {(localStorage.getItem('can_delete_post') == 'true') ?  <Button onClick={(e)=> this._handleDeleteProjects(e,id)} size='sm' type="button" className="mx-2"  variant='outline-danger' > Delete </Button>  : ''}
               

        </td>
        
      </tr>
    );
  };

  let alert = this.props.location.state ? this.props.location.state :  '';


    return (<>
      {
        
      }
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>New Projects</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Projects List</h4>
          <p className="mb-0">New Projects</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

       <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Project Id</th>
              <th className="border-bottom">Project Title</th>
              <th className="border-bottom">Project Time</th>
              <th className="border-bottom">User Name</th>
              <th className="border-bottom">ZipCode</th>
              <th className="border-bottom">Created At</th>
              <th className="border-bottom">Action</th>

              
            </tr>
          </thead>
          <tbody>
          { (this.state.loading == true) ? <PlaceholderList colspan='7' /> : this.state.list.map(t => <TableRow  {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            {/* <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination> */}
          </Nav>
          <small className="fw-bold">
            Showing <b>{totaljobs}</b> entries
          </small>
          {(this.state.DisableLoadmore) ? '' : 
          <Button  onClick={this.HandleLoadMore} > 
            Load more 
          </Button> }
        </Card.Footer>
      </Card.Body>
    </Card>
     <SweetAlert2 {...this.state.swal} />
    </>);
  }

}


export default withSwal(JobList);



