import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import ShowCompanyDetails from './ShowOwnerDetails';
import Api from '../../config/Api';
import PlaceholderList from '../components/Placeholder';  
import TruckDetails from '../components/TruckDetails';  


class OwnerView extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      user_type: 'Owner',
      data:{},
      error:'',
      success:false,
      loading:true
    } 
  }

  componentDidMount(){
    // let data = {id:this.props.match.params.id};
    let data = {params:{user_id:this.props.match.params.id}};
    Api.get('user',data).then((response)=>{
      console.log(response.data.data);
      let response_data = response.data.data;
      this.setState({
        data:response_data,
        loading:false
      })
      console.log(this.state.data);
    }).catch((e) => {
      console.info(e);
      //   this.setState({
      //   error:e.response.data.message,
      //   loading:false
      // })
    })

  }


  render(){
    if(this.state.loading === true){
      return (<table><PlaceholderList colspan ="colspan" /></table>);
    }
     return (
       
      <Row className="mt-4"  >
        {/* <Col xs={12} xl="4" >
          <Card border="light" className="bg-white shadow-sm mb-4 ">
          <Card.Header>Profile Image</Card.Header>
          <Card.Img height="310px" variant="top" src={(this.state.data.image == null) ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : this.state.data.image  } />
         
          </Card>
        </Col> */}
        <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>{this.state.user_type} Details</Card.Header>
              <Card.Body>
              <ShowCompanyDetails  
              variant = 'user'
              data={this.state.data } 
               />
               <TruckDetails
                truck_detail={this.state.data.truck_detail } 
               />
              </Card.Body>
            </Card>
        </Col>
        {/* <Col xs={12} xl={10}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Products ({this.state.data.products.length})</Card.Header>
              <Card.Body>

              <ChatList variant={1} chat={this.state.data.products }  ></ChatList>
              </Card.Body>
            </Card>
        </Col> */}

      </Row>);
  }

}



export default OwnerView;
