import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom'
import { uploadFile } from 'react-s3';
import { Braket } from "aws-sdk";
const  MyInputs = (props) => {

  switch (props.type) {
    case 'file':
      
    return (
    <Col md={6} key={props.index} className="mb-3">      
    <Form.Label>{props.lable}</Form.Label>
      <Form.Control onChange={props.submithandle}  name={props.name}   type={props.type}  placeholder={`Enter your ${props.lable}`} />

    <Error message={props.error} />
   </Col>)
    break;

    case 'checkbox':
      return (
        <Col md={6} key={props.index} className="mb-3">      
        <Form.Label>{props.lable}</Form.Label>
         <Form.Check  label="Active"  type="checkbox" checked={props.value} onChange={props.submithandle}  name={props.name}  
            placeholder={`Enter your ${props.lable}`} />
            
        <Error message={props.error} />
       </Col>)
       break;
  
  
    default:

        return (
          <Col md={6} key={props.index} className="mb-3">      
          <Form.Label>{props.lable}</Form.Label>
           
           <Form.Control onChange={props.submithandle}  name={props.name}  
            type={props.type}  value={props.value} placeholder={`Enter your ${props.lable}`} />
          <Error message={props.error} />
         </Col>)
  }



}

const form  = [
  {
    lable : 'Guset Admin Email',
    name :'guset_admin_email',
    type :'email',
    required:true
   },
   {
    lable : 'Guset Admin Password',
    name :'guset_admin_password',
    type :'text',
    required:true
   },
   {
    lable : 'Access Allow',
    name :'access_allow',
    type :'checkbox'
   }
];
const config = {
  bucketName: "yellowishs3bucket",
  dirName: "admin/category",
  region: "ap-south-1",
  accessKeyId: "AKIAWMQBRPCBP34UWJOK",
  secretAccessKey: "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"
}

class SettingView extends React.Component{

  constructor(props){
    super(props);
    this.state={
      first_name:'',
      last_name:'',
      email:'',
      password:'',
      user_status:false,
      can_delete_post:false,
      new_password:'',
      confirm_password:'',
      amenity_id:'',
      amenity_name:'',
      amenity_status:true,
      error:{},
      success:false,
      loading:false,
      success:false,
      
      guestAccounts:[
      ]
    } 
  }

  componentDidMount(){
    this.fetchGusets()
   
    
  }


  fetchGusets = () => {
    Api.get('gusets').then((response)=>{
      console.log(response.data);
      this.setState({guestAccounts:response.data.data})
    }).catch((e)=> {
        this.setState({
        error:e.response.data.message
      })
    })
  }

  
  handleChangeinput = (e) => {
    // e.preventDefault();
    var InputName = e.target.name;
    var InputType = e.target.type
   
    console.log(InputName,[InputType,e.target.checked])
     
    switch (InputType) {
      case 'file':
        this.setState({loading:true})
        uploadFile(e.target.files[0], config)
        .then(data => this.setState({ [InputName]:data.location,loading:false }))
        .catch(err => console.error(err))
      break;

      case 'checkbox':
      // console.log(InputName,e.target.checked)
      console.log('InputName','e.target.checked')

        this.setState({ [InputName]:e.target.checked})

      break;
  
      default:
        this.setState({ [InputName]:e.target.value })
      }
    }


  handleSubmit = e => {
    e.preventDefault()

    if(this.state.new_password === this.state.confirm_password){

      Api.post('update-password',{new_password:this.state.new_password}).then((response)=> {

        alert(response.data.message);
        this.setState({new_password:'',confirm_password:''});
       
      }).catch((e)=>{
        console.log(e);
        let message = e.response.data.message;
        alert(message);
        // this.setState({error:message})
      })

    }else{
      alert("Passwords Don't Match");
    }

  }


  handleAddGuset = e => {
    e.preventDefault()

    // if(this.state.new_password === this.state.confirm_password || )){

      const Data = {
        first_name:this.state.first_name,
        last_name:this.state.last_name,
        email:this.state.email,
        password:this.state.password,
        active_status:(this.state.user_status) ? 1 : 0,
        can_delete_post:(this.state.can_delete_post) ? 1 : 0,
      }

      Api.post('add-guset',Data).then((response)=> {

        alert(response.data.message);
        this.setState({first_name:'',last_name:'',email:'',password:''});
        this.fetchGusets()
      }).catch((e)=>{
        console.log(e);
        let message = e.response.data.message;
        alert(message);
        // this.setState({error:message})
      })

    // }else{
    //   alert("Passwords Don't Match");
    // }

  }

  handleCanChangePost = (e,G,OldStayus) => {


    const NewStatus = (OldStayus) ? 0 : 1

    Api.post('guset/canpost',{email:G.email,CanPostStatus:NewStatus}).then((response)=> {

      alert(response.data.message);
      this.fetchGusets()
    }).catch((e)=>{
      console.log(e);
      let message = e.response.data.message;
      alert(message);
      // this.setState({error:message})
    })

  }

  handleCanChangeStatus = (e,G,OldStayus) => {

    const NewStatus = (OldStayus) ? 0 : 1

    Api.post('guset/active',{email:G.email,Active:NewStatus}).then((response)=> {

      alert(response.data.message);
      this.fetchGusets()
    }).catch((e)=>{
      console.log(e);
      let message = e.response.data.message;
      alert(message);
      // this.setState({error:message})
    })
  }

  handleDeleteGuset = (e,G) => {


   if(window.confirm('you want to delete this account')){
    Api.post('guset/delete',{email:G.email}).then((response)=> {
      alert(response.data.message);
      this.fetchGusets()
    }).catch((e)=>{
      console.log(e);
      let message = e.response.data.message;
      alert(message);
      // this.setState({error:message})
    })
  }
   }


  render(){

   










    if(this.state.success){
      return <Redirect  to={{
              pathname: '/manufacturers',
              state: "Manufacturer Upadetd Successfully"
            }} />
    }
     return (
       
      <Row className="mt-4"  >
        <Col xs={6} xl={6}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Header> 
                <h3>  Reset Password</h3>
              </Card.Header>
              <Card.Body>
                <form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col md={7} className="mb-3">      
                      <lable className=""> Email </lable>
                      <Form.Control  type='email' disabled value="admin@reparet.com" placeholder={`Enter your `} required />
                    </Col>
                    <Col md={7} className="mb-3">      
                      <lable className="">New Password </lable>
                      <Form.Control  type='password' onChange={this.handleChangeinput}  value={this.state.new_password} placeholder={`Enter your new password`} name="new_password" required />
                    </Col>
                    <Col md={7} className="mb-3">      
                      <lable className="">Confirm Password </lable>
                      <Form.Control name="confirm_password"  type='password' onChange={this.handleChangeinput}   value={this.state.confirm_password} placeholder={`Confirm Your new password`} required />
                    </Col>
                    <Col md={12} className="mt-4">
                        <Button variant="primary"  type="submit">Update </Button>
                    </Col>
                  </Row>
                </form>
          
              </Card.Body>
            </Card>
        </Col>
        <Col xs={6} xl={6}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Header> 
                <h3> Add guest Accounts </h3>
              </Card.Header>
              <Card.Body>
                <form onSubmit={this.handleAddGuset}>
                  <Row>
                  <Col md={6} className="mb-3">      
                      <lable className="">First Name </lable>
                      <Form.Control  type='text'  value={this.state.first_name}  name="first_name" onChange={this.handleChangeinput}  placeholder={`Enter first name `} required />
                    </Col>
                    <Col md={6} className="mb-3">      
                      <lable className="">Last Name </lable>
                      <Form.Control  type='text' value={this.state.last_name}  name="last_name"  onChange={this.handleChangeinput}  placeholder={`Enter last name `} required />
                    </Col>
                    <Col md={6} className="mb-3">      
                      <lable className=""> Email </lable>
                      <Form.Control  type='email'  value={this.state.email}  name="email"   onChange={this.handleChangeinput}  placeholder={`Enter email `} required />
                    </Col>
                    <Col md={6} className="mb-3">      
                      <lable className="">Password </lable>
                      <Form.Control  type='password' name="password" onChange={this.handleChangeinput}  value={this.state.password}  placeholder={`Enter password`} required />
                    </Col>

                    <Col md={4} className="mb-3">      
                      <Form.Label> States </Form.Label>
                      <Form.Check  label="Active" onChange={this.handleChangeinput}   checked={this.state.user_status}  type="checkbox" name="user_status"   /> 
                    </Col>
                    <Col md={5} className="mb-3">      
                      <Form.Label> Can Delete Posts  </Form.Label>
                      <Form.Check  label="Yes"  onChange={this.handleChangeinput}  checked={this.state.can_delete_post}  type="checkbox"  name="can_delete_post"   /> 
                    </Col>
                    <Col md={12} className="mt-4">
                        <Button variant="primary"  type="submit">Submit </Button>
                    </Col>
                  </Row>
                </form>
          
              </Card.Body>
            </Card>
        </Col>
        <Col xs={12} xl={12}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Header> 
                <h3>  Guest Accounts </h3>
              </Card.Header>
              <Card.Body>
               
                            <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">Active</th>
                    <th scope="col">can <br></br>Delete Post</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>

                  {this.state.guestAccounts.map((GusetAccount,Index)=> {
                    return (
                    
                    <tr>
                      <th scope="row">{Index+1}</th>
                      <td> {GusetAccount.first_name} {GusetAccount.last_name} </td>
                      <td> {GusetAccount.email}</td>
                      <td> <Button size="sm"  onClick={(e) => { this.handleCanChangeStatus(e,GusetAccount,GusetAccount.active_status)  }}   variant={(GusetAccount.active_status) ? 'success' : 'danger'} >
                        {(GusetAccount.active_status) ? 'Active' : 'Blocked'} </Button>
                        <br></br>
                        <a className="text-info" > Change </a>
                         </td>
                      <td> <Button onClick={(e) => { this.handleCanChangePost(e,GusetAccount,GusetAccount.can_delete_post)}} size="sm" variant={(GusetAccount.can_delete_post) ? 'success' : 'danger'} >
                        {(GusetAccount.can_delete_post) ? 'Yes' : 'No'} </Button> 
                        <br></br>

                        <a className="text-info" > Change </a>
                        
                        </td>

                      <td> 
                        <Button size="sm" onClick={(e) => { this.handleDeleteGuset(e,GusetAccount)}}  variant="outline-danger" >Delete </Button>
                      </td>
                    </tr>);
                  })}
                 
                  
                </tbody>
              </table>
          
              </Card.Body>
            </Card>
        </Col>
       
      </Row>);
  }

}



export default SettingView;
