import React from 'react'

import { Image } from '@themesberg/react-bootstrap';
import ImageRow from '../components/LoadImage'
import moment from "moment-timezone";



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);

const RowElement =(props) => (
  <span>{props.value}<br></br></span>
);

const ShowCompanyDetails = (props) => (
  <table class="table">
  <tbody>
    
  <MyRow 
    lable="ID"
        value={props.data.uuid} />
      
  <MyRow 
    lable="Name"
    value={props.data.first_name+' '+props.data.last_name} />
    
    <MyRow 
    lable="Email"
    value={props.data.email} />

  <MyRow 
    lable="Email verified"
        value={(props.data.email_verified) ? 'Verified' : 'Not Verified'} />
      

  <MyRow 
    lable="Phone"
        value={props.data.phone} />
      
  <MyRow 
    lable="Account Status"
        value={(props.data.active_status) ? 'Active' : 'Inactive'} />
   
   <MyRow 
    lable="Background Verification Status"
        value={(props.data.Provider.background_verified) ? 'Verified' : 'Not Verified'} />
      
    
   <MyRow 
    lable="Background Verified On"
        value={(props.data.Provider.background_verified) ? moment(props.data.Provider.background_verified_at).format('Do MMM YY') : '-'} />
      
   <MyRow 
    lable="Company Name"
    value={props.data.Provider.company_name} />

    <MyRow 
        lable="Address"
        value={props.data.Provider.location} />
      
    <MyRow 
        lable="Bio"
        value={props.data.Provider.bio} />

      
<MyRow 
    lable="Specialities"
        value={(props.data.ProviderCategories.length < 1) ? 'No Specialities added yet' : props.data.ProviderCategories.map(t => <><RowElement value={ t.Category.name } /></>)} />
    
    
{/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}
  
    
    {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}
 
 

 <br></br>
 


  </tbody>
</table>
)

export default ShowCompanyDetails


