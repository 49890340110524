import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import {Error,Success} from '../../components/Alerts';
import ShowCompanyDetails from './ShowCompanyDetails';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom';
import { Image } from 'semantic-ui-react'
import PlaceholderList from '../components/Placeholder';  
import JobList from './ProviderProjects/JobList.js';  


class CompanyView extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      user_type: 'Provider',
      data:{},
      error:'',
      success:false,
      loading:true
    } 
  }


  onClickVerify() {
    let id = arguments[0];
    
    
    let Confirm  = window.confirm('Do you want to verify background for this user?');
    
    if(Confirm){
      this.setState({
        loading:true
      })
      Api.post('users/verify-background', { user_id: id })
        .then((response) => { 
          console.log(response)
          let data = {params:{user_id:this.props.match.params.id}};
          Api.get('provider',data).then((response)=>{
            console.log(response.data.data);
            let response_data = response.data.data;
            this.setState({
              data:response_data,
              loading:false
            })
            console.log(this.state.data);
          }).catch((e) => {
            console.info(e);
            //   this.setState({
            //   error:e.response.data.message,
            //   loading:false
            // })
          })
          this.setState({
            loading:false
          })
        }).catch((e)=> {
          alert(e.response.data.message);
        })
    }

  }

  componentDidMount(){
    // let data = {id:this.props.match.params.id};
    let data = {params:{user_id:this.props.match.params.id}};
    Api.get('provider',data).then((response)=>{
      console.log(response.data.data);
      let response_data = response.data.data;
      this.setState({
        data:response_data,
        loading:false
      })
      console.log(this.state.data);
    }).catch((e) => {
      console.info(e);
      //   this.setState({
      //   error:e.response.data.message,
      //   loading:false
      // })
    })

  }


  render(){
    if(this.state.loading === true){
      return (<table><PlaceholderList colspan ="colspan" /></table>);
    }
     return (
       
      <Row className="mt-4"  >
        {/* <Col xs={12} xl="4" >
          <Card border="light" className="bg-white shadow-sm mb-4 ">
          <Card.Header>Profile Image</Card.Header>
          <Card.Img height="310px" variant="top" src={(this.state.data.image == null) ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : this.state.data.image  } />
         
          </Card>
        </Col> */}
          
        <Col xs={12} xl={8}>
         {(!this.state.data.Provider.background_verified)?<Button onClick={this.onClickVerify.bind(this, this.state.data.id)} variant='success'> Mark Background as Verified </Button>:<></>}
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>{this.state.user_type} Details</Card.Header>
              <Card.Body>
              <ShowCompanyDetails  
              variant = 'user'
              data={this.state.data } 
               />
               <JobList providerId={ this.state.data.id } />
              </Card.Body>
            </Card>
        </Col>
        {/* <Col xs={12} xl={10}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Products ({this.state.data.products.length})</Card.Header>
              <Card.Body>

              <ChatList variant={1} chat={this.state.data.products }  ></ChatList>
              </Card.Body>
            </Card>
        </Col> */}

      </Row>);
  }

}



export default CompanyView;
