import React from 'react'

import { Image } from '@themesberg/react-bootstrap';
import ImageRow from '../components/LoadImage'



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);

const RowElement =(props) => (
  <span>{props.value}<br></br></span>
);

const ShowDriverDetails = (props) => (
  <table class="table">
  <tbody>
    
  <MyRow 
    lable="ID"
        value={props.data.user_number} />
      
  <MyRow 
    lable="Name"
        value={props.data.name} />
    
    <MyRow 
    lable="Email"
    value={props.data.email} />

  <MyRow 
    lable="Email verified"
        value={(props.data.verified) ? 'Verified' : 'Not Verified'} />
      

  <MyRow 
    lable="Phone"
        value={props.data.phone} />
      
  <MyRow 
    lable="Document Uploaded"
        value={(props.data.onboarding_status) ? 'Uploaded' : 'Not Uploaded'} />
      
  <MyRow 
    lable="Account Status"
        value={(props.data.active) ? 'Active' : 'Inactive'} />
      
      {/* <MyRow 
    lable="References"
        value={(props.data.references.length < 1) ? 'No References' : props.data.references.map(t => <RowElement value={'Company Name : ' + t.company_name + ', Phone : ' + t.phone} />)} /> */}
      
      <MyRow 
    lable="Documents"
        value={(props.data.documents.length < 1) ? 'No Documents' : props.data.documents.map(t => <><RowElement value={'Document Type : ' + t.document_type } /><ImageRow 
        lable="Document Preview : "
            value={t.document_link} /></>)} />
      

      
    
    
{/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}
  
    
    {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}
 
 

 <br></br>
 


  </tbody>
</table>
)

export default ShowDriverDetails


