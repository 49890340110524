import React,{useState, useEffect } from 'react'

import { Image, ListGroup } from '@themesberg/react-bootstrap';
import { Storage } from 'aws-amplify';

const ImageRow = (props) => {
  const [image, setImage] = useState('https://via.placeholder.com/150');
  
  useEffect(() => {
    // console.log(props)
    loadImage(props);
  }, []);
  
  const loadImage = (props) => {
    Storage.get(props.value).then(resp => {
      console.log(resp);
      setImage(resp)
    }).catch(err => { console.log(err); });
  }
  return (
      <ListGroup.Item>
        <Image height="150px" variant="top" src={image} />
      </ListGroup.Item>
  )
}

export default ImageRow;