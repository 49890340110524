import React,{useState, useEffect } from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'

import { Image,ResponsiveEmbed } from '@themesberg/react-bootstrap';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Storage } from 'aws-amplify';
import ImageRow from '../components/LoadImage'





const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);

const RowElement =(props) => (
  <span>{props.value}<br></br></span>
);


const ShowShipperDetails = (props) =>(
    <table class="table">
      <tbody>
    
    
      <MyRow 
    lable="ID"
        value={props.data.uuid} />
      
  <MyRow 
    lable="Name"
    value={props.data.first_name+' '+props.data.last_name} />
    
    <MyRow 
    lable="Email"
    value={props.data.email} />

  <MyRow 
    lable="Email verified"
        value={(props.data.email_verified) ? 'Verified' : 'Not Verified'} />
      

  <MyRow 
    lable="Phone"
        value={props.data.phone} />
      
  <MyRow 
    lable="Account Status"
        value={(props.data.active_status) ? 'Active' : 'Inactive'} />
      
      </tbody>
    </table>
  )

export default ShowShipperDetails


