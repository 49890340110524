import React from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'


import { faStar } from '@fortawesome/free-solid-svg-icons';



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);

const RowElement =(props) => (
  <span>{props.value}<br></br></span>
);



const ShowDetails = (props) => (
  <table class="table">
  <tbody>
    
  <MyRow 
    lable="Nameswsws"
    value={props.name} />
    
    <MyRow 
    lable="Email"
    value={props.email} />

  <MyRow 
    lable="Email verified"
        value={(props.verified) ? 'Verified' : 'Not Verified'} />
      

  <MyRow 
    lable="Address"
        value={props.address} />
      

  <MyRow 
    lable="Zip Code"
        value={props.zip_code} />
      

  <MyRow 
    lable="Wallet Balance"
        value={props.wallet_balance} />
      
      <MyRow 
    lable="References"
        value={(props.reference.length < 1) ? 'No References' : props.reference.map(t => <RowElement value={'Name : ' + t.name + ', Phone : ' + t.phone} />)} />
      

      <MyRow 
    lable="Amenities"
        value={(props.amenities.length < 1) ? 'No amenities' : props.amenities.map(t => <RowElement value={t.amenity_name} />)} />
      

      <MyRow 
    lable="Children"
        value={ (props.children.length < 1) ? 'No children Listed' : props.children.map(t => <RowElement value={t.name} />)}/>
      
    
    
{/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}
  
    
    {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}
 
 

 <br></br>
 


  </tbody>
</table>
)

export default ShowDetails


