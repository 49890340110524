import React from "react";
// import { Icon, Label, Menu, Table, Card, Button } from 'semantic-ui-react'

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Table,
  Nav,
  Pagination,
  Alert,
} from "@themesberg/react-bootstrap";

import { faStar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import ImageRow from "./LoadImage";

const MyRow = (props) => (
  <tr>
    <th scope="row">{props.lable}</th>
    <td>
      <p>{props.value}</p>
    </td>
  </tr>
);

const MyCopyRow = (props) => (
  <tr >
    <th scope="row">{props.lable}</th>
    <td
       className="text-primary"
      onClick={async() => {
        // navigator.clipboard.writeText(props.value);
        if ("clipboard" in navigator) {
          await navigator.clipboard.writeText(props.value);
        } else {
          document.execCommand("copy", true, props.value);
        }
        // toast("Wow so easy!")
        alert("Link copied to clipboard")
      }}
      style={{cursor:"pointer"}}
    >
      <p>Copy Share link</p>
    </td>
  </tr>
);

const MyLocationRow = (props) => (
  <tr>
    <th scope="row">Open {props.lable} in Map</th>
    <td className="text-primary">
      <a
        target="_blank"
        href={
          "http://maps.google.com/?q=" + props.value[0] + "," + props.value[1]
        }
      >
        Open Location in Map
      </a>
    </td>
  </tr>
);

const LinkRow = (props) => (
  <tr>
    <th scope="row">{props.lable}</th>
    <td>
      <Link to={`/${props.path}/${props.uid}`}>{ props.value }</Link>
    </td>
  </tr>
);

const RowElement = (props) => (
  <span>
    {props.value}
    <br></br>
  </span>
);

const ShowJobDetails = (props) => (
  <>
    <table class="table">
      <tbody>
        <MyRow lable="Project Title" value={props.data.project_title} />
        <MyRow
          lable="Posted On"
          value={moment(props.data.createdAt).format("Do MMM YY")}
        />

        {props.data.location_geometry ? (
          <MyLocationRow
            lable="Project Title"
            value={props.data.location_geometry.coordinates}
          />
        ) : (
          ""
        )}
        <MyCopyRow lable="Share Link" value={props.data.project_code} />
        <MyRow lable="Project address" value={props.data.address || '-'} />
        <MyRow lable="Project zipcode" value={props.data.zipcode || '-'} />
        <MyRow lable="User Email" value={props.data.consumer_details.email || '-'} />
        <LinkRow lable="User Name" path={"user"} uid={props.data.consumer_details.uuid} value={props.data.consumer_details.first_name+" "+props.data.consumer_details.last_name || '-'} />
        <MyRow lable="Provider" value={props.data.provider_details.email || '-'} />
        {/* <MyRow lable="Project Title" value={props.data.project_title} />
        <MyRow lable="Project Title" value={props.data.project_title} /> */}
        {/* {
      (props.data.job_status == 'STARTED' || props.data.job_status == 'REROUTED') ? <MyLocationRow lable="current location" value={props.data.dropoff_location_point.coordinates} /> : ''
        }     */}

        {/* {(props.data.pods)?<MyRow lable="Proof of delivery" value={(props.data.pods.length < 1) ? 'No Documents' : props.data.pods.map(t => <><ImageRow  lable="" value={t.image} /></>)} />: ''} */}

        <tr></tr>

        {/* 
    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
     */}

        {/* {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''} */}

        <br></br>
      </tbody>
    </table>
    {/* <ToastContainer /> */}
    {/* <iframe 
  width="700" 
  height="400" 
  frameborder="0" 
  scrolling="no" 
  marginheight="0" 
  marginwidth="0" 
  src="https://maps.google.com/maps?q=30.6804481,76.726084&hl=es&z=14&amp;output=embed"
    >
      
 </iframe> */}
  </>
);

export default ShowJobDetails;
