import React from 'react'

import { Image } from '@themesberg/react-bootstrap';
import ImageRow from './LoadImage'



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);

const RowElement =(props) => (
  <span>{props.value}<br></br></span>
);

const TruckDetails = (props) => (
  <table class="table">
    <thead>
      <h3>Truck Details</h3>
  </thead>
  <tbody>
    
  <MyRow 
    lable="Truck Number"
    value={props.truck_detail.truck_number} />
    
    <MyRow 
    lable="Truck ID"
    value={props.truck_detail.truck_id} />

  <MyRow 
    lable="Model"
        value={props.truck_detail.model} />
  <MyRow 
    lable="Year"
        value={props.truck_detail.year} />
    
  <MyRow 
    lable="Registration Number"
        value={props.truck_detail.registration_number} />
      

  <MyRow 
    lable="Manufacturer"
        value={props.truck_detail.manufacturer} />
      

  <MyRow 
    lable="Truck Type"
        value={props.truck_detail.truck_type} />
      
      <MyRow 
    lable="Trailers"
        value={(props.truck_detail.trailers.length < 1) ? 'No References' : props.truck_detail.trailers.map(t => <RowElement value={`Trailer Number : ${t.trailer_number}(${t.registration_number}), Model : ${t.model}`} />)} />
      
      <br></br>
  </tbody>
</table>
)

export default TruckDetails


