import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import ShowJobDetails from '../components/ShowJobDetails';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom';
import { Image } from 'semantic-ui-react'
import PlaceholderList from '../components/Placeholder';  
import ChatList from '../components/Chatlist';  


class JobView extends React.Component{

  constructor(props){
    super(props);
    this.state={
      data:{},
      error:'',
      success:false,
      loading:true
    } 
  }

  componentDidMount(){
    // let data = {id:this.props.match.params.id};
    let data = {params:{id:this.props.match.params.id}};
    Api.get('/project/details',data).then((response)=>{
      console.log(response.data.data);
      let response_data = response.data.data;
      this.setState({
        data:response_data,
        loading:false
      })
      // console.log(this.state.data);
    }).catch((e) => {
      console.info(e);
      //   this.setState({
      //   error:e.response.data.message,
      //   loading:false
      // })
    })

  }


  render(){
    if(this.state.loading === true){
      return (<table><PlaceholderList colspan ="colspan" /></table>);
    }
     return (
       
      <Row className="mt-4"  >
        {/* <Col xs={12} xl="4" >
          <Card border="light" className="bg-white shadow-sm mb-4 ">
          <Card.Header>Profile Image</Card.Header>
          <Card.Img height="310px" variant="top" src={(this.state.data.image == null) ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : this.state.data.image  } />
         
          </Card>
        </Col> */}
        <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Job Details</Card.Header>
             <Card.Body>
              <ShowJobDetails  
              variant = 'job'
                 data={this.state.data}
                 map={"https://maps.google.com/maps?q="+ this.state.data.lat +","+this.state.data.lng+"&hl=es&z=14&amp;output=embed"}
              // verified={this.state.data.verified }
              // company={this.state.data.Company}
              />
              </Card.Body>
            </Card>
        </Col>
        {/* <Col xs={12} xl={10}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Header>Products ({this.state.data.products.length})</Card.Header>
              <Card.Body>

              <ChatList variant={1} chat={this.state.data.products }  ></ChatList>
              </Card.Body>
            </Card>
        </Col> */}

      </Row>);
  }

}



export default JobView;
